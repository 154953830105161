
.carousel-item {
  height: 440px;
}
.carousel-item img {
  height: 100%;
  width: 100%;
}

#carousel-home {
  img {
    object-fit: cover;
  }
  @media screen and (max-width: 576px) {
    margin: -1rem ;
  }
}
